import React from "react"
import styled from "styled-components"

export const HeaderWrapper = styled.header`
  min-height: 75px;
  width: 100%;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 25px 0;
  background-color: #00509a;
  color: #f8f8f8;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
      width: 100%;
      margin: 0 auto;
      letter-spacing: 1.5px;
      text-transform: uppercase;
  }
`;

export const ContentRow = styled.div`
  width: 100%;
  display: inline-block;
`;