import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { HeaderWrapper, ContentRow } from "./header.stc"

const Header = ({ title, date }) => (
  <HeaderWrapper>
      <h1>{title}</h1>
      <h4>{date}</h4>
  </HeaderWrapper>
)

export default Header
