import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header/header"
import Img from "gatsby-image"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import BlockContent from '@sanity/block-content-to-react'

import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs"
import "react-web-tabs/dist/react-web-tabs.css"

export const proposalTemplateData = graphql`
  query proposalTemplateQuery($id: String!) {
    proposal: sanityProposal(id: { eq: $id }) {
      title
      writtenOn(formatString: "MMM DD, YYYY")
      agency {
        agency {
          eml
          tel
          name
          logo {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          contactName
        }
      }
      client {
        client {
          name
          contactName
        }
      }
      mainImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
      content {
        _key
        title
        hero {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
        body {
          ... on SanityTools {
            list {
              name
              url
              description
              _id
              logo {
                asset {
                  fluid(maxWidth: 500) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            _type
          }
          ... on SanityBlockContent {
            _key
            _type
            _rawBodyCopy 
          }
          ... on SanityPriceList {
            _key
            _type
            _rawList
          }
        }
      }
    }
  }
`

const NavLogo = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0;
  background: #101f3d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;

  div {
    width: 85%;
  }
`

const TabWrapper = styled(Tabs)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
`

const ListWrapper = styled(TabList)`
  width: calc(100% / 5);
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  position: sticky;
  &[aria-orientation="vertical"] {
    margin-right: 0 !important;
    border: none !important;
  }
`

const TabItem = styled(Tab)`
  padding: 18px;
  border-left: 10px solid rgba(17, 17, 17, 0);

  background-color: #ffffff;
  color: rgba(48, 48, 48, 0.7);
  font-weight: 400;
  font-size: 0.85em;
  letter-spacing: 0.5px;
  margin-bottom: 1px;
  min-height: 18px;

  transition: all 0.2s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f8f8f8;
  }

  &:focus {
    box-shadow: none;
    border-color: none;
    outline: none;
  }

  &[aria-selected="true"] {
    color: rgba(30, 30, 30, 1) !important;
    border-left: 5px solid #e69f2d;
    background-color: #f8f8f8 !important;
    font-weight: 600;

    &:after {
      display: none;
    }
  }
`

const PanelItem = styled(TabPanel)`
  width: calc(400% / 5);
  background: #f9f9f9;
  transition: all 0.5s ease;
`

const Hero = styled(BackgroundImage)`
  width: 100%;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 50px;

  &::before,
  &::after {
    filter: blur(0px) brightness(40%) contrast(100%) saturate(200%);
  }

  div.overlay {
    background-color: rgba(17, 17, 17, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  h2 {
    padding: 50px 50px 45px 50px;
    margin: 0 auto;
    color: #fafafa;
    font-size: 3em;
    letter-spacing: 1.4px;
  }
`

const ContentWrapper = styled.div`
  width: 800px;
  margin: 0 auto;
  position: relative;
  h1, h2 {
    color: #0094ca;
  }

  h3 {
    color: #007099;
  }

  a {
    color: #101f3d;
    text-decoration: none;
    font-size: .7em;
    text-transform: uppercase;
  }

  p, ul, li {
    color: #333;
    letter-spacing: .9px;
    font-size: .95em;
    line-height: 1.5;
  }
`;

const ToolWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToolIcon = styled(Img)`
  width: 20%;
`;

const ToolCopy = styled.div`
  width: 80%;
  padding: 25px;
  padding-left: 50px;
  text-align: justify;
  h3 {
    font-size: 2.5em;
    margin-bottom: 7px;
    color: #0094ca;
  }

  a {
    color: #101f3d;
    text-decoration: none;
    font-size: .7em;
    text-transform: uppercase;
  }

  p {
    color: #333;
    letter-spacing: .9px;
    font-size: 1em;
    line-height: 1.5;
  }
`;

const LineItemRow = styled.div`
  border: none;
  border-top: 1px solid #D7D9E0;
  overflow: auto;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemDetails = styled.div`
  border-right: 1px solid #D7D9E0 !important;
  width: 70%;
  display: inline-block;
  float: left;

  h3 {
    text-transform: uppercase;
    font-size: 0.85em !important;
    letter-spacing: 1.2px;
  }

  p, ul, li {
    font-weight: normal !important;
    font-size: 0.9em !important;
    color: #999 !important;
    line-height: 1.5em !important;
    width: 95%;
  }

  li {
    padding: 5px 0;
  }
`;

const ItemDetailCopy = styled(BlockContent)`

`;

const ItemPrice = styled.div`
  width: 25%;
  float: left;
  padding-left: 15px;

  span {
    line-height: 1.5em;
    font-size: 1.3em;
    color: #444;
    letter-spacing: 1.4px;
    font-weight: 300;
  }

  sup {
    font-size: 1em;
    letter-spacing: 1.5px;
    color: #666;
  }

  span.small {
    font-size: .9em;
    color: #555;
    letter-spacing: 2px;
  }

  span.opts {
    font-size: .7em;
    color: #555;
    font-variant: small-caps;
    margin-left: 25px;
    display: block;
  }
`;

const ItemTags = styled.div`
  display: flex;
  flex: row wrap;
`;

//TODO ReWrite tags schema as radio button list

const Tag = styled.div`
  padding: 5px 10px;
  font-size: 8px;
  letter-spacing: 0.08em;
  background-color: #33C3C7;
  color: #FFF;
  border-radius: 4px;
  display: inline;
  font-weight: bold;
  margin: 10px 5px;
  text-transform: uppercase;
`;

const ProposalTemplate = props => {
  const { data, errors } = props
  const proposal = data.proposal
  const agency = proposal.agency.agency
  const client = proposal.client.client
  const sections = proposal.content
  return (
    <Layout>
      <Header title={proposal.title} date={proposal.writtenOn} />

      <TabWrapper
        defaultTab={sections[0]._key}
        vertical
        className="vertical-tabs"
      >
        <ListWrapper>
          <NavLogo>
            <Img fluid={agency.logo.asset.fluid} />
          </NavLogo>

          {sections.map((section, i) => (
            <TabItem tabFor={section._key} key={i}>
              {section.title}
            </TabItem>
          ))}
        </ListWrapper>

        {sections.map((section, i) => (
          <PanelItem tabId={section._key} key={i}>
            <Hero fluid={section.hero.asset.fluid} alt="">
              <h2>{section.title}</h2>
            </Hero>

            <ContentWrapper>
              {section.body.map((body, i) => (
                <>
                  {body._type === 'blockContent' && <BlockContent blocks={body._rawBodyCopy}/>}
                  {body._type === 'tools' && 
                    body.list.map((tool, i) => (
                      <ToolWrapper>
                        <ToolIcon fluid={tool.logo.asset.fluid} alt={tool.name} />
                        <ToolCopy>
                          <h3>{tool.name}</h3>
                          <a href={tool.url}>{tool.url}</a>
                          <p>{tool.description}</p>
                        </ToolCopy>
                      </ToolWrapper>
                    ))
                  }
                  {body._type === 'priceList' && 
                    body._rawList.map((item, i) => (
                      <LineItemRow>
                        <ItemDetails>
                          <h3>{item.title}</h3>
                          <ItemTags>
                            {item.optional ? <Tag>optional</Tag> : ''}
                            {item.monthly ? <Tag>monthly</Tag> : ''}
                          </ItemTags>
                          <ItemDetailCopy blocks={item.description} />
                        </ItemDetails>
                        <ItemPrice>
                          <sup>$</sup><span>{item.price}</span><span className='small'>.00{item.monthly ? <sub>/month</sub> : ''}</span>
                          
                        </ItemPrice>
                      </LineItemRow>
                    ))
                  }
                </>
              ))}
            </ContentWrapper>
          </PanelItem>
        ))}
      </TabWrapper>
    </Layout>
  )
}


export default ProposalTemplate


// <>
//   {body.list.map((tool, i) => (
//     <ToolWrapper>
//       <h4>{tool.name}</h4>
//       <a href={tool.url}>{tool.url}</a>
//       <p>{tool.description}</p>
//       <Img fluid={tool.logo.asset.fluid} alt={tool.name} />
//     </ToolWrapper>
//   ))}
// </>,